import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import ServiceListItem from "./serviceListItem"

const ServiceList = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const variants = {
    visible: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    hidden: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  return (
    <motion.div
      className="overflow-hidden"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
    >
      {props.services.map(service => (
        <ServiceListItem service={service} key={service.id} />
      ))}
    </motion.div>
  )
}

export default ServiceList
