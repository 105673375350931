import React, { useEffect } from "react"

import Img from "gatsby-image"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

function createMarkup(text) {
  return { __html: text }
}

const ServiceListItem = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={controls}
      className="container mt-32"
    >
      <div className="hero-rect w-2/5 h-112 bg-gray-50 relative z-20"></div>

      <h2 className="flex flex-col -mt-96 ml-20 text-50 lg:text-70 xl:text-80 relative z-40">
        {props.service.titleTop}
        <span className="-mt-6 text-gray-100">{props.service.titleBottom}</span>
      </h2>

      <div className="w-3/4 ml-auto md:mr-32 md:-mt-56">
        <div className="w-full relative z-30">
          <Img
            fluid={props.service.img.childImageSharp.fluid}
            className="hero-img"
          />
        </div>
        <div className="bg-white -ml-48 md:-mt-32 md:ml-32 py-12 px-24 relative z-40">
          <p className="text-xl">{props.service.text}</p>

          <div className="grid md:grid-cols-2">
            <ul
              className="mt-12 list-disc ml-8"
              dangerouslySetInnerHTML={createMarkup(props.service.listLeft)}
            />
            <ul
              className="md:mt-12 list-disc ml-8"
              dangerouslySetInnerHTML={createMarkup(props.service.listRight)}
            />
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default ServiceListItem
