import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import InnerHero from "../components/heros/inner"
import IconLists from "../components/lists/iconLists"

import Box from "../components/textBlocks/box"
import ServiceList from "../components/lists/serviceList"
import LogoList from "../components/lists/logoList"
import LogoCarousel from "../components/lists/logoCarousel"

function ServicesPage(props) {
  const logos = [props.data.rics, props.data.ssip, props.data.constructionline]
  const services = [
    {
      id: 1,
      titleTop: "Quantity",
      titleBottom: "Surveying",
      img: props.data.quantitySurveying,
      text:
        "As professional quantity surveyors, we effectively stand in our client's shoes and manage the costs and legal processes of your building project.",
      listLeft:
        "<li>Cost Planning and estimating throughout the entire building process</li><li>Whole Life Costing</li><li>Risk assessment and mitigation</li><li>Financial Modelling</li><li>Green book appraisals</li><li>Advice on procurement strategies and advantages, disadvantages and risks associated with each</li><li>Advice on forms of contract and bespoke alterations</li>",
      listRight:
        "<li>Preparation of pricing documents suitable for tender to include:<ul><li>Preliminaries</li><li>Bills of Quantities</li><li>Schedule of Work</li><li>Activity Schedules</li><li>Construct Sum Analysis</li></ul></li><li>Tender action and assessment</li><li>Cash flow projections</li><li>Interim valuations and recommendations</li><li>Change management control</li><li>Final Account negotiation</li>",
    },
    {
      id: 2,
      titleTop: "Project",
      titleBottom: "Management",
      img: props.data.proManage,
      text:
        "Our project managers oversee all aspects of the building process, working closely with all stakeholders to develop plans, establish timetables, and determine reliable budgets. We are responsible for ensuring the project is completed on budget and within scope:",
      listLeft:
        "<li>Project execution plans(PEP)</li><li>Brief development</li><li>Design team selection</li><li>Feasibility assessments</li><li>Business Case preparation and reviews</li><li>Due diligence</li><li>Schedule management</li><li>Design management</li><li>Schedule management</li><li>Risk Management</li>",
      listRight:
        "<li>Value engineering</li><li>Stakeholder management</li><li>Procurement advice</li><li>Contract advice</li><li>Contract administration</li><li>Employer’s representative</li><li>Change management</li><li>Post-completion reviews</li><li>Lessons learnt reports</li>",
    },
    {
      id: 3,
      titleTop: "Fund",
      titleBottom: "Advisory",
      img: props.data.fund,
      text:
        "Our team help banks, funders and individuals improve their investment decisions and risk profile by carrying out initial due diligence and ongoing project monitoring on development projects.",
      listLeft:
        "<li>Pre-acquisition due diligence</li><li>Statutory compliance review</li><li>Health and safety audits</li><li>Coordination of fire audit compliance</li><li>Technical team coordination and management</li><li>Risk assessments</li><li>Remedial costs</li><li>Red flag reports</li><li>Commercial review of vendor reports</li><li>Remediation strategy and cash flow preparation</li><li>Initial due diligence reports</li>",
      listRight:
        "<li>Facility agreement review</li><li>Commercial insurance advice</li><li>Risk assessments and mitigation</li><li>Construction contract advice</li><li>Development budgets</li><li>Benchmarking</li><li>Programme assessment</li><li>Cash flow monitoring</li><li>Construction monitoring</li><li>Invoice tracking, payment verification</li>",
    },
    {
      id: 4,
      titleTop: "Other Specialist",
      titleBottom: "Services",
      img: props.data.specialist,
      text:
        "At Hawthorne Consulting we can offer our clients a range of other specialist services to help bring success to your project:",
      listLeft:
        "<li>Quantum Expert Witness</li><li>Employers Agent</li><li>Claims Consultancy</li>",
      listRight:
        "<li>Project Audits</li><li>Insurance Reinstatement Valuations</li><li>Loss Assessing</li>",
    },
  ]

  return (
    <Layout>
      <InnerHero
        subtitle="Services"
        title="Decades Of"
        titlegray="Experience"
        img={props.data.hero.childImageSharp.fluid}
      />

      <div className="container mt-32 md:mt-40 overflow-hidden">
        <div className="flex flex-wrap lg:-mx-20">
          <Box
            dash="45 plus years"
            title="Decades of experience, at your service."
            text="With decades of experience, Hawthorne Consulting can tailor the service we provide to suit your needs and objectives. The key services we provide are:"
          />
          <div className="w-full lg:w-2/6 lg:px-20 flex items-center">
            <IconLists />
          </div>
        </div>
      </div>

      {/* <LogoCarousel logos={props.data.logos} /> */}
      <ServiceList services={services} />
      <LogoList logos={logos} />
    </Layout>
  )
}

export default ServicesPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    logos: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "logos/carousel" }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    hero: file(relativePath: { eq: "services-hero.jpg" }) {
      ...fluidImage
    }
    imageOne: file(relativePath: { eq: "home-hero-l.jpg" }) {
      ...fluidImage
    }
    quantitySurveying: file(relativePath: { eq: "ourServices-hero.jpg" }) {
      ...fluidImage
    }
    proManage: file(relativePath: { eq: "project-management.jpg" }) {
      ...fluidImage
    }
    fund: file(relativePath: { eq: "fund-advisory.jpg" }) {
      ...fluidImage
    }
    specialist: file(relativePath: { eq: "other-specialist.jpg" }) {
      ...fluidImage
    }
    rics: file(relativePath: { eq: "logos/rics.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    constructionline: file(relativePath: { eq: "logos/constructionline.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ssip: file(relativePath: { eq: "logos/ssip.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
